/* Footer styling */
.footer {
  background-color: var(--White);
  border-top: 5px solid var(--Sand);
}

/* Footer container with compact spacing */
.footerContainer {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

/* Footer sections */
.footerSection {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

/* Footer headings */
.footerSection > h4 {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: var(--Sand);
}

/* Footer links list */
.footerSection > ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footerSection  > ul > li {
  margin-bottom: 5px;
}

.footerLink {
  text-decoration: none;
  color: var(--Black);
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.footerLink:hover {
  color: var(--Sand);
}

/* Social media icons */
.iconList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;
  margin: 0;
}

.iconList li {
  list-style: none;
}

.icon {
  font-size: 48px;
  color: var(--Sand);
  transition: color 0.3s ease-in-out;
}

.icon:hover {
  scale:1.2;
  color: var(--Sand);
}

/* Footer logo styling */
.footerLogo {
  width: 160px;
  margin-bottom: 0;
}

/* Footer address text */
.footerText {
  margin-top: 0;
  font-size: 0.9rem;
  line-height: 1.4;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .footerContainer {
      flex-direction: column;
      text-align: center;
  }

  .footerSection {
      margin-bottom: 5px;
  }

  .iconList {
      justify-content: center;
  }
}
