.checkboxWrapper {
  display: flex;
  align-items: center; /* Vertically center checkbox and label */
  cursor: pointer;
  padding: 8px 0; /* Padding between checkbox and label */
}

.checkboxIcon {
  color: var(--Green);
  transition: 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  padding-right: 4px;
}
.checkboxIcon > svg {
  border-radius: 2px;
}

.label {
  margin: 0;
  font-size: 14px;
}

.label:hover {
  cursor: pointer;
}
