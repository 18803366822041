/* projectcomp.css */

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    min-width: 500px;
    background: #F5F5F7; /* White */
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--Green);
  }
  
  .modalHeader {
    color: var(--Green);
    font-family: AMIA;
  }
  
  .modalLabel {
    margin: 10px;
  }
  
 
  
  .modalButton {
    margin: 10px;
    /* Add more button styling here if needed */
  }
  