  
  .foundProductItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    background-color: var(--Grey);
    margin: 5px;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
  }
  .foundProductItem:hover {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  }

  .productInfo {
    display: flex;
    flex-direction: column;
    width: 400px;
    gap: 5px;
  }
  
  .productName {
    margin: 2px 0;
    font-size: 14px;
    max-width: 350px; /* Set a max width */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis; /* Add the "..." */
    display: block; /* Ensure it applies properly */
  }
  
  
  .producerName {
    margin: 2px 0;
    font-size: 12px;
    color: #555;

  }
  
  .productLink {
    margin: 2px 0;
    font-size: 12px;
    color: #555;
    max-width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .linkStyle {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .linkText {
    color: var(--Black);
    text-decoration: none;
    font-size: 12px;
    
  }

  .img{
    width: 70px;
    max-height: 80px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  
  }
  


  .ulstyle {
    position: absolute;
    top: 100%; /* Makes it appear directly below input */
    left: 0;
    width: 100%; /* Ensures it aligns with the input */
    min-width: 400px;
    box-shadow: 0px 0px 5px 2px var(--Grey);
    max-height: 45vh;
    overflow-y: scroll;
    background-color: var(--White);
    z-index: 1000;
    padding: 0px;
    transform: translateY(-5px); /* Moves it down slightly */

  }
  
  
  /* Scrollbar Styling */
  .ulstyle::-webkit-scrollbar {
    width: 6px; /* Scrollbar width */
  }
  
  .ulstyle::-webkit-scrollbar-track {
    background: transparent; /* Track background */
  }
  
  .ulstyle::-webkit-scrollbar-thumb {
    background: var(--Grey); /* Thumb color */
    border-radius: 3px; /* Rounded edges for thumb */
  }
  
  .ulstyle::-webkit-scrollbar-thumb:hover {
    background: darken(var(--Grey), 10%); /* Darker thumb on hover */
  }
  
  /* For Firefox */
  .ulstyle {
    scrollbar-width: thin; /* Thin scrollbar */
    scrollbar-color: var(--Grey) transparent; /* Thumb and track color */
  }
  

  