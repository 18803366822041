/* Base styles for the navbar */
.nav {
  background-color: var(--White);
  margin: auto;
  min-width: 320px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 5px solid var(--Sand);
  padding: 15px 15px;
  height: fit-content;
}

/* Styles for the links and navbar items container */
.linkAndNavs {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex: 1;
}

/* Logo styles */
.logo {
  width: 20vw;
  min-width: 150px;
  margin-right: 2vw;
}

/* Styles for the links container */
.linksContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 20px;
  max-width: 600px;
}

/* Styles for individual link items */
.linkItem {
  list-style: none;
  margin: 0;
}

/* Styles for the NavLink components */
.navLink {
  font-family: "GOTHICB";
  font-weight: bold;
  text-decoration: none;
  color: var(--Sand);
  font-size: 1.3vw;
  padding: 5px 5px;
  border-radius: 10px 10px 0 0;
  transition: background-color 0.3s, color 0.3s, border 0.3s;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 3px solid transparent;
}

/* Active link styles */
.activeNavLink,
.navLink:hover {
  color: var(--Green);
  background-color: var(--White);
  border-color: var(--Sand);
}

/* Styles for the right-side buttons */
.navButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  flex-shrink: 0;
}

/* Styles for the login button */
.loginBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

/* Styles for individual buttons */
.button {
  display: flex;
  font-family: "GOTHICB";
  color: var(--Sand);
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
  font-size: 0.9vw;
}

/* Hover effect for buttons */
.button:hover {
  color: var(--Green);
}

/* Styles for icons */
.icon {
  width: 32px;
  color: var(--Sand);
  transition: color 0.3s;
}

/* Hover effect for icons */
.button:hover .icon {
  color: var(--Green);
}

/* styles.module.css */
.activeLink,
.activeLink .icon {
  color: var(--Green);
}

@media (max-width: 1100px) {
  .iconContainer {
    position: fixed;
    top: 1vh;
    right: 4vh;
    z-index: 1100;
    cursor: pointer;
  }

  .nav {
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;
    height: fit-content;
  }

  .logo {
    width: 40vw;
    min-width: 150px;
    margin-left: 0;
    margin-bottom: 5%;
  }

  .linksContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .linkItem {
    margin-top: 4vh;
    width: 100%;
  }

  .menu {
    border-left: 3px solid var(--Sand);
    position: fixed;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background-color: var(--White);
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    transform: translateX(100%);
    transition: transform 0.7s ease-in-out;
    z-index: 1000;
  }

  .menuOpen {
    transform: translateX(0);
  }

  .navLink {
    font-size: 1.2rem;
    padding: 10px;
    width: 100%;
  }

  .navButtons {
    flex-direction: column;
    height: auto;
    width: 100%;
  }
}

.betaBanner {
  position: relative;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--Sand);
  color: var(--White);
  font-size: 1.2rem;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 0;
  overflow: hidden;
  animation: slideDown 0.5s forwards ease-in-out;
}

/* Ensure the text moves smoothly inside the banner */
.betaBanner > span {
  width: 100%;
  font-weight: bold;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide text that overflows */
}

/* Fix for height animation */
@keyframes slideDown {
  0% {
    height: 0;
  }
  100% {
    height: 25px; /* Adjust the height as needed */
  }
}
