.featureBarContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically (if needed) */
  background-color: var(--Sand);
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 85%;
}

.featureItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 150px;
  margin-bottom: 20px;
  margin-top: 25px;
}

.icon {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.text {
  font-size: 1em;
  color: white;
  font-weight: bolder;
}
