.pageStyle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-left: 0;
  margin-top: 10vh;
  background-color: var(--White);
}

.loginCont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.loginContButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.logInputStyle {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center;     /* Center horizontally */
  width: 100%;
}

.inputStyle {
  width: 100%;
  height: 40px;
  font-size: 3vh;
  border-radius: 5px;
  border: 1px solid var(--Grey);
  margin-bottom: 10px;
}

.buttonStyle {
  width: 150px;
  height: 45px;
  background-color: var(--Green);
  color: var(--White);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.5vh;
}

.fontStyle {
  font-size: 3vh;
  color: var(--Sand);
  font-weight: bold;
  text-align: justify;
  margin-left: 0;
}

.loginError {
  color: var(--Black);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-left: 0;
  font-size: large;
}



/* RESET PASSWORD */

.resetMessage {
  font-size: 16px;
  color: var(--Black);
  text-align: center;
  margin-top: 20px;
}

.resetMessage a {
  color: var(--PrimaryColor);
  font-weight: bold;
  text-decoration: none;
}

.resetMessage a:hover {
  text-decoration: underline;
}
