/* .table {
    width: 90%;
    border-collapse: collapse;
    table-layout: fixed;
}

.tableCell,
.tableHeaderCell {
    border: 2px solid var(--Sand);
    text-align: center;
    width: auto !important;
    height: 7vh;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: var(--White);
    padding-left: 5px;
    padding-right: 5px;
    min-width: 100px;
} */

/* .tableHeaderRow th {
    border-bottom: 5px solid var(--Sand);
    border-top: 0px;
} */

.Button {
    font-family: GOTHIC;
    font-size: 13px;
    color: var(--Black);
    background-color: var(--White);
    width: 100px;
    height: 30px;
    border: 2px solid var(--Sand);
    border-radius: 18px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 8px;
    

}

.Button:hover {
    background-color: var(--Sand);
    color: var(--White);
    border: 2px solid var(--White);
}