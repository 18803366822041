.safetyLevelIcon {
  position: relative;
  display: flex;
  margin: 0px;
}

.tooltip {
  all: unset;
  display: flex; 
  visibility: hidden; 
  background-color: var(--White); 
  font-family: GOTHIC; 
  color: var(--Black); 
  text-align: center; 
  padding: 10px; 
  position: absolute; 
  bottom: 100%; 
  transform: translateX(-20%); 
  width: auto; 
  white-space: nowrap; 
  z-index: 1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

.safetyLevelIcon:hover .tooltip {
  visibility: visible;
  padding: 5px;
}
