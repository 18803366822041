@font-face {
  font-family: 'GOTHIC';
  src: url('./Fonts/GOTHIC.woff2') format('woff2'),
       url('./Fonts/GOTHIC.woff') format('woff');
  font-weight: 1;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GOTHICB';
  src: url('./Fonts/GOTHICB.woff2') format('woff2'),
       url('./Fonts/GOTHICB.woff') format('woff');
  font-weight: 1;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AMIA';
  src: url('./Fonts/Amiamie-Black.woff2') format('woff2'),
       url('./Fonts/Amiamie-Black.woff') format('woff');
  font-weight: 1;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'CenturyGothic';
  src: url('./Fonts/CenturyGothic.woff2') format('woff2'),
       url('./Fonts/CenturyGothic.woff') format('woff');
  font-weight: 1;
  font-style: normal;
  font-display: swap;

}


body, html {
  margin: 0;
  padding: 0;
  display: flex;
  background-color: var(--White);
  flex-direction: column;
  font-family: 'CenturyGothic', sans-serif;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scrollbar-color: rgba(255, 255, 255, 0.5) ; /* Firefox */
}



nav {
  flex-shrink: 0; 
}



.main-content {
  flex-grow: 1;
}

