/* Container for the table */
.thable {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5vh;
    width: 100%; 
    height: 100%; 
    overflow: hidden; 
    padding-bottom: 5vh;
    padding-top: 5vh;
}



.searchbar-and-radio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    font-family: CenturyGothic;
}

  input {
    padding: 10px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100px;
    margin: 5px;
    margin-right: 10px;
  }
  form > div > div > p, form > div > div > div > p
  {
    font-family: GOTHIC;
    font-size: 16px;
    display: flex;
    justify-content: center;
    width: 150px;
  }
  form > div > label{
    font-family: GOTHIC;
    font-size:x-large;
    font-weight: bolder;
    margin: 20px;
  }