/* Slider style */

.slidercontainer {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.sliderstyle {
  -webkit-appearance: none;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  width: 120px;
  height: 20px;
}

.sliderstyle::-webkit-slider-runnable-track {
  background: var(--Grey);
}

.sliderstyle::-moz-range-track {
  background: var(--Grey);
}

.sliderstyle::-ms-track {
  background: var(--Grey);
}

.sliderstyle::-webkit-slider-thumb {
  appearance: none;
  border: none;
  width: 20px;
  height: 20px;
  background: #33512b;
  border-radius: 50%;
}

.sliderstyle::-moz-range-thumb {
  border: none;
  width: 20px;
  height: 20px;
  background: #33512b;
  border-radius: 50%;
}

.numbercontainer {
  width: 25px;
  min-width: none;
  cursor: pointer;
  margin-right: 25px;
}

.numbercontainer:hover::after {
  content: attr(title);
  position: absolute;

  left: -20%;
  top: -200%;
  white-space: nowrap;
  z-index: 100;
  background: #f0f0f0; /* Light grey background */
  border: 1px solid #d0d0d0; /* Light grey border */
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 0.8rem; /* Smaller font size for the tooltip */
}
