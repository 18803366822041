.filter {
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
}

.resetbutton {
  font-family: GOTHIC;
  font-size: 13px;
  color: var(--Black);
  background-color: var(--White);
  width: 100px;
  height: 30px;
  border: 2px solid var(--Sand);
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 10px 10px 10px 0;
}

.resetbutton:hover {
  background-color: var(--Sand);
  color: var(--White);
  border-color: var(--White);
}

.gwpslidercontainer {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.sliderstyle {
  -webkit-appearance: none;
  appearance: none;
  width: 120px;
  height: 20px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sliderstyle::-webkit-slider-runnable-track,
.sliderstyle::-moz-range-track,
.sliderstyle::-ms-track {
  background: var(--Grey);
  height: 5px;
  border-radius: 5px;
}

.sliderstyle::-webkit-slider-thumb,
.sliderstyle::-moz-range-thumb {
  border: none;
  width: 20px;
  height: 20px;
  background: #33512b;
  border-radius: 50%;
}

.producercheckbox,
.compliancecheckbox {
  font-family: GOTHIC;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin: 5px 10px 5px 0;
  padding: 10px;
  display: flex;
  align-items: center;
}

.producercheckbox > input,
.compliancecheckbox > input {
  width: 13px;
  margin-right: 10px;
}
