.squareBoxGroup {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5vh;
  width: 100%;
  padding-bottom: 25px;
  height: fit-content;
}

.squareBoxText {
  font-size: 1.6em;
  color: var(--Black);
  margin-bottom: 10px;
}

.boldtext {
  color: var(--Sand);
  font-weight: bold;
}

.author {
  font-size: 1em;
  text-align: right;
  color: var(--Black);
  margin-top: 10px;
}

.infoBox {
  margin-top: 3vh;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  align-items: center;
  background-color: var(--Sand);
  width: 40%;
  border-radius: 25px;
}

.card {
  display: flex;
  flex-direction: row;
  align-items: left;
  padding: 20px 20px;
  width: 100%;
  border-radius: 25px;
  color: var(--White);
}

.cardText {
  font-size: 1.2em;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 2vw;
  max-width: 50%;
}

.logo {
  width: 15vh;
  padding: 0px 10px;
  justify-content: center;
  align-self: center;
}

.logoBorder {
  display: flex;
  width: 15vh;
  height: 15vh;
  justify-self: center;
  align-self: center;
  background-color: var(--White);
  object-fit: cover;
  border-radius: 25px;
}

@media (max-width: 768px) {
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin-top: 24px;
  }

  .infoBox {
    margin-top: 3vh;
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    align-items: center;
    background-color: var(--Sand);
    width: 70%;
    border-radius: 25px;
  }

  .squareBoxText {
    font-size: 1.2em;
    color: var(--Black);
    margin-bottom: 10px;
  }
}
