

.ProjektPage {
  
  
    background-color: var(--White);
    width: 100%;
    height: 80vh;
    overflow: hidden;
    position: relative;
    z-index: 0;
    padding-top: 5px;
}
  


.work-area {
    display: flex;
    flex-direction: column;
    margin-left: 5% ;
    margin-top: 2%;
    height: max-content;
    width: fit-content;
}

.cont-box{
    width: 94vw;
    overflow-y: auto
}


.cont-box-grid {
    margin-top: 1vh;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, minmax(400px, 1fr));
    align-items: center;
    justify-items: center;
    row-gap: 60px;
    height: 65vh;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 2px solid var(--Green);
    border-radius: 20px;
    overflow-y: auto; 
}



/* For Grid Layout */
.cont-box-grid .project-item {
    width: 250px;
    height: 145px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px; /* Space between the project name and the boxes */
    border: var(--Sand) 2px solid;
    border-radius: 20px;
    background-color: var(--White); /* Adjust as needed */
    text-decoration: none;
}
.cont-box-grid .project-item, .grid-new-project {
    position: relative; /* This is needed to position the ::before pseudo-element correctly */
    /* ... (rest of your styles) */
}
.cont-box-grid .project-item:hover::before, .grid-new-project:hover::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(128, 128, 128, 0.1); /* This is a semi-transparent gray */
    border-radius: 20px; /* To maintain the rounded corners */
    pointer-events: none; /* To ensure the overlay doesn't interfere with any interactions */
} 


.cont-box-grid .project-item h2 {
    display: flex;
    align-self: start;
    margin-left: 20px;
    margin-top: 5px;
    margin-bottom: 5px; /* space between the title and images */
    color: var(--Black);
    font-size: large;
    font-weight: bolder;

}


.project-boxes-container {
    display: flex;
     
    gap: 10px; /* Space between each box */
    
}

.project-box {
    width: 60px; /* Adjust as needed */
    height: 60px; /* Adjust as needed */
    
    border: 2px solid var(--Black); /* Adjust as needed */
    border-radius: 20px; /* Adjust as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    object-position: 50% 50%;
}
.proj-modal-container{
    display: flex;
}

.proj-modal {
    display: flex;
    max-width: fit-content;
    color: var(--Green) ;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 5px;
    height: fit-content;
    background: var(--White);
    border: 2px solid var(--Green);
    font-size: 1.5rem;
    text-align: center;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    background: var(--White);
    
    
}



.proj-modal:hover {
    background: var(--Sand);
    color: var(--White);
    border: 2px solid var(--Sand);
}






