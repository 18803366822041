.filterSortButton {
    flex: 1 1 calc(15% - 20px); 
    max-width: calc(15% - 20px); 
    height: 50px;
    min-width: 8vw;
    /* background-color: var(--Grey);  */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 2px solid var(--Sand);
    color: var(--Black);
    box-sizing: border-box; 
    border-radius: 15px; 
    font: 11px GOTHIC; 
   
  }
  .filterSortButton > h3 {
    padding-left: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-height: 50px;
    overflow: hidden;
  }
  
  .closeIcon {
    cursor: pointer;
    font-size: x-large;
  }
  
  .filterSortButton:hover {
    background-color: var(--Sand);
    color: var(--White);
  }