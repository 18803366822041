.teamContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 20px;
  gap: 40px;
  overflow-x: auto;
}

.teamHeader {
  font-family: AMIA;
  font-weight: bold;
  color: var(--Green);
  font-size: 2em;
  margin-left: 50px;
}

.teamMember {
  display: flex;
  flex-direction: column; /* Vertical alignment within each member */
  align-items: center; /* Center-align image and details */
  text-align: center; /* Center-align text */
  flex-shrink: 0; /* Prevent shrinking of the member container */
  width: 200px; /* Set a fixed width for each team member */
  gap: 15px; /* Add spacing between image and text */
  margin: 10px; /* Optional: Add margins between members */
}

.imgCont {
  width: 150px; /* Adjust image size */
  height: 150px; /* Maintain a square aspect ratio */
  overflow: hidden; /* Crop overflowing parts */
  border-radius: 20px;
}

.teamMemberImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.teamDetails {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  color: var(--Black);
}

.teamName {
  font-weight: bold;
  font-family: CenturyGothic;
  font-size: 1.2em; /* Adjust font size */
  color: var(--Black);
  margin: 10px;
}

.teamTitle {
  font-size: 1em; /* Adjust font size */
  font-family: CenturyGothic;
  color: var(--Black);
  margin: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .teamContainer {
    flex-direction: column; /* Stack members vertically on smaller screens */
    align-items: center;
  }

  .teamMember {
    width: 100%; /* Full-width team members */
    max-width: 300px; /* Prevent them from being too wide */
  }

  .imgCont {
    width: 120px; /* Smaller images for mobile */
    height: 120px;
  }
}
