/* OmPage.module.css */

.omPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 0;
  width: 100%;
  background-color: var(--white);
}

.omPageSplit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 75%;
  margin-bottom: 30px;
  margin-top: 30px;
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
}

.splitHeadline {
  font-weight: bolder;
  font-size: x-large;
}

.omPageSplitText {
  flex: 1;
  padding-right: 40px;
  text-align: left;
  font-size: 1.1em;
}

.omPageSplitImage {
  width: 50%;
  object-fit: cover;
  border-radius: 10px;
  margin-top: 5px;
  max-width: 100%; /* No overflow on small screens */
}

.omPageImgRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px;
}

.omPageImage {
  width: 300px;
  height: auto;
  border-radius: 20px;
  border: 1px solid grey;
  object-fit: cover;
  margin: 1%;
}

.omPageTextColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 400px;
  font-family: CenturyGothic;
}

.omPageHeader {
  font-family: "GOTHICB";
  color: var(--green);
  font-size: 4em;
}

.omPageText {
  color: var(--black);
  font-size: 1.1em;
}

@media (max-width: 768px) {
  /* For tablets and smaller screens */
  .omPageSplit {
    flex-direction: column; /* Stack items vertically on smaller screens */
    width: 100%; /* Take full width */
  }

  .omPageSplitText {
    padding: 20px; /* Add padding to the text to prevent it from going edge-to-edge */
    font-size: 1.2em; /* Adjust font size for readability on mobile */
  }

  .omPageSplitImage {
    width: 100%; /* Image takes full width on small screens */
    margin-top: 10px; /* Add space between text and image */
  }
}

@media (min-width: 1920px) {
  .omPageContainer {
    max-width: 1440px; /* Ensure your layout doesn’t stretch too wide */
    margin: 0 auto; /* Center the container */
  }
}
