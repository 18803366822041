/* styles.module.css */


.tableContainer {
    font-family: GOTHIC;
    width: fit-content;
    min-width: 600px;
    max-width: 1500px; /* Adjust as necessary */
    margin: auto; /* Center the table container */
    background-color: var(--White); /* Background color for the table */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Adds a subtle shadow around the table */
    padding: 8px; /* Adds space inside the container */
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .tableTitle {
    font-family: GOTHICB;
    text-align: center;
    font-size: 24px;
    color: var(--Black); /* Dark text for better readability */
    padding-bottom: 16px; /* Space below the title */
    width: 100%;
  }
  
  .materialTable table {
    min-width: 600px; /* Adjust as necessary */
    max-width: 1500px; /* Adjust as necessary */
    width: auto;
    border-collapse: collapse;
    font-size: 14px; /* Smaller font size */
  }w
  
  .materialTable th,
  .materialTable td {
    border: 1px solid #ddd; /* Subtle border for cells */
    padding: 8px;
    max-width: 62px;
  }
  
  .materialTable th {
    background-color: var(--Grey); /* Green background for headers */
    color: var(--Black); /* White text for headers */
    font-weight: bold;
    font-style: normal;
  }
  
  /* Header style for the 'Parameter' and 'Unit' columns */
  .materialTable th:first-child,
  .materialTable th:nth-child(2) {
    background-color: var(--Grey); /* Darker background for the first two header columns */
    color: var(--Black);
  }
  
  /* Style for the 'Environmental impacts pr. m3' header */
  .tableContainer h2 {
    background-color: var(--Green);
    color: var(--White);
    padding: 8px;
    margin: -20px -20px 16px -20px; /* Aligns the header with the edge of the table container */
  }
  
  /* Alternating row colors */
  .materialTable tr:nth-child(even) {
    background-color: var(--Grey);
  }
  
  .captionTable {
    margin-top: 8px;
    font-size: smaller;
    text-align: center;
    position: relative; /* Or position: absolute if needed */
    top: 0;
    left: 0;
  }
  
  .numberContainer {
    position: relative;
    cursor: pointer;
    text-align: left;
    width: fit-content;
  }
  
  .numberContainer:hover::after {
    content: attr(title);
    position: absolute;
    left: 0;
    top: -200%;
    white-space: nowrap;
    z-index: 100;
    background: #f0f0f0; /* Light grey background */
    border: 1px solid #d0d0d0; /* Light grey border */
    font-size: 0.8rem; /* Smaller font size for the tooltip */
  }
  
  /* Container for the radio buttons */
  .unitSelector {
    margin: 10px 0;
    display: flex;
    gap: 15px; /* Space between radio buttons */
    align-items: center; /* Align items (text and button) vertically */
    font-family: 'Courier New', Courier, monospace;
  }
  
  /* Styling for each radio label */
  .unitSelector label {
    display: flex;
    align-items: center; /* Center text and button vertically */
    cursor: pointer;
    font-size: 16px;
    color: var(--Black);
    position: relative;
    padding-left: 30px; /* Space for custom radio button */
  }
  
  /* Hide the default radio button */
  .unitSelectorInput {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create the custom radio button (outer circle) */
  .unitSelector label::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 50%; /* Start at 50% of the label's height */
    transform: translateY(-50%); /* Shift up by half of its own height to center */
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid var(--Black); /* Unselected state border */
    background-color: var(--White); /* Background color */
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  
  /* Checked state outer circle (background and border) */
  .unitSelectorInput:checked + label::before {
    background-color: var(--Sand); /* Checked state color */
    border-color: var(--Black);
  }
  