/* Cookie Banner Overlay */
.cookieOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Cookie Modal */
.cookieModal {
    background: var(--White);
    color: var(--Black);
    padding: 30px;
    border-radius: 10px;
    max-width: 600px;
    text-align: left;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    font-family: GOTHIC, sans-serif;
}

/* Modal Title */
.cookieTitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Modal Text */
.cookieText {
    font-size: 0.9rem;
    color: var(--Black);
}

/* Cookie Category */
.cookieCategory {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Always Active Label */
.alwaysActive {
    color: var(--Green);
    font-weight: bold;
}

.buttonContainer {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
}

