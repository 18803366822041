.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltipText {
    visibility: hidden;
    position: absolute;
    background-color: var(--Black);
    color: var(--White);
    text-align: left;
    border-radius: 5px;
    padding: 8px;
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    font-size: 0.9rem;
    width: 250px;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
    opacity: 0;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 8px; /* Space between the tip text and the link */
}

.tooltip:hover .tooltipText {
    visibility: visible;
    opacity: 1;
}

.tooltipLink {
    color: #1e90ff;
    text-decoration: underline;
    font-size: 0.85rem;
    text-align: right; /* Align the link to the right */
}

.tooltipLink:hover {
    color: #63b3ed; /* Slightly lighter blue on hover */
    text-decoration: none; /* Remove underline on hover */
}
