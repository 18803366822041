.boxstyle {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--Black);
    max-width: 15vw;
    height: 10vh;
    border: 4px solid var(--Sand);
    border-radius: 25px;
    font: 1em 'AMIA';
    padding: 10px; /* Add padding to the text */
    transform: scale(1);
    transition: transform 0.5s; /* Smooth transition */
}

.boxstyle:hover {
    background-color: var(--Sand);
    color: var(--White);
    cursor: pointer;
    transform: scale(1.05); /* Slight enlargement to indicate active state */

    transition: ease-in-out 0.5s; /* Smooth transition */
    transition: transform 0.5s; /* Smooth transition */

}


.active {
    background-color: var(--Sand); /* Example active background color */
    color: var(--White); /* Example active text color */
    transform: scale(1.05); /* Slight enlargement to indicate active state */
    transition: transform 0.5s; /* Smooth transition */
  }