.vilkaarContainer {
    display: flex;
    flex-direction: row;
    height: 60vh;
    padding: 5vh 10vw;

}

.vilkaarContent {
    flex: 1;
    background: var(--Sand);
    color: var(--Black);
    margin: 0 2vw;
    border-radius: 25px;
    text-align: center;
    box-shadow: 0 0 15px 0 var(--Black);
    padding: 2vh 2vw;
}

.vilkaarContent:hover {
    background: #c4a179;
    box-shadow: 0 0 25px 0 var(--Black);


}