/* DropdownAndModal.module.css */

.flexContainer {
    display: flex;
    align-items: center;
  }
  
  .modalTrigger {
    cursor: pointer;
    color: var(--Green);
    z-index: 1;
  }
  
  /* Improved modal background to cover the entire viewport */
  .modalBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Updated modal to be responsive and centered */
  .modal {
    background: var(--White);
    width: 90%;
    max-width: 600px;
    margin: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
  }
  
  /* Close button for the modal (optional) */
  .closeButton {
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: var(--Black);
  }
  
  /* Improved project creation container */
  .projectCreationContainer {
    width: 50%;
    background: var(--White);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    transition: background 0.3s;
    border-right: 1px solid var(--Grey);
  }
  
  .projectCreationContainer:hover {
    background: var(--Grey);
  }
  
  .projectTitle {
    color: var(--Green);
    margin-bottom: 20px;
    text-align: center;
  }
  
  .margin10px {
    margin: 10px 0;
  }
  
  .projectListContainer {
    width: 50%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  
  .projectAddTitle {
    color: var(--Green);
    margin-bottom: 10px;
  }
  
  .projectList {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    max-height: 300px;
  }
  
  .noProjects {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: var(--Black);
  }
  
  .projectItem {
    cursor: pointer;
    padding: 10px;
    display: flex;
    align-items: center;
    transition: background 0.3s;
    border-bottom: 1px solid var(--Grey);
  }
  
  .projectItem:hover {
    background: var(--Grey);
  }
  
  .projectCheckmark,
  .projectPlus {
    margin-right: 10px;
    color: var(--Green);
  }
  
  .modal > input[type='text'] {
    width: 100%;
    padding: 8px;
    border: 1px solid var(--Grey);
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .modal > button {
    padding: 10px 15px;
    background-color: var(--Green);
    color: var(--White);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .modal > button:hover {
    background-color: #2a3e28; /* Darker shade of var(--Green) */
  }
  /* Media Query for Mobile Devices */
@media (max-width: 768px) {
    .modal {
      flex-direction: column;
      width: 95%;
      padding: 15px;
      max-height: 90vh;
      overflow-y: auto;
    }
  
    .projectCreationContainer,
    .projectListContainer {
      width: 100%;
      padding: 0;
    }
  
    .projectCreationContainer {
      border-right: none;
      border-bottom: 1px solid var(--Grey);
      margin-bottom: 20px;
    }
  
    .projectList {
      max-height: none;
    }
  
    .closeButton {
      top: 10px;
      right: 10px;
    }
  }
  
  /* Adjustments for Very Small Screens */
  @media (max-width: 480px) {
    .modal {
      padding: 10px;
    }
  
    .projectTitle,
    .projectAddTitle {
      font-size: 1.2em;
    }
  
    .modal > button {
      width: 100%;
      padding: 8px;
      font-size: 1em;
    }
  
    .modal > input[type='text'] {
      padding: 6px;
    }
  
    .projectItem {
      padding: 8px;
    }
  
    .projectCheckmark,
    .projectPlus {
      margin-right: 8px;
    }
  }
  