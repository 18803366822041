.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5vh;
  width: 100vw;
  height: fit-content;
  padding-bottom: 25px;
}

.cardContainer {
  width: 60%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* Wrapper to hold arrows and card container */
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;
}
/* display: "flex", width: "50vw",justifySelf:"center", justifyItems: "center", paddingTop:"25px" ,marginTop: "200vh", flexDirection: "column"  */
/* Group layout (each card pair) */
.group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  flex-shrink: 0;
  width: 100%;
}

.logo {
  width: 4vw;
  height: auto;
  display: flex;
  justify-content: center;
  margin-right: 1vw;
}

.cardContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1vw;
}

.card {
  width: 45%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cardTitle {
  font-size: 1vw;
  color: var(--Sand);
  justify-self: center;
  margin: 0 0;
}

.slidingContainer {
  display: flex;
  width: 100%;
  transition: transform 0.5s ease-in-out;
}

.arrow {
  cursor: pointer;
  font-size: 4rem;
  color: var(--Grey);
  transition: transform 0.2s ease-in-out;
}

@media (max-width: 768px) {
  .cardContainer {
    width: 90%;
  }

  .group {
    flex-direction: column; /* Stack cards */
    align-items: center;
  }

  .card {
    width: 90%;
    max-width: 320px;
  }

  .cardTitle {
    font-size: 1.2rem;
  }

  .cardContent p {
    font-size: 1rem;
  }

  /* Make arrows bigger and ensure they're always visible */
  .arrow {
    font-size: 3rem;
    padding: 10px;
    display: block !important; /* Force arrows to stay visible */
  }
  .logo {
    width: 15%;
    padding: 8px;
  }

  .description {
    margin: 24px;
  }
}
