/* General Styling */
.registerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    font-family: Arial, sans-serif;
}

/* License Selection Card */
.licenseCard {
    padding: 30px;
    border-radius: 15px;
    text-align: center;
    width: 60vh;
}




.licenseCardHeader h3 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #333;
}

/* License Card Options */
.licenseCardOptions {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.licenseCardOption {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30vh;
    height: 30vh;
    border: 2px solid var(--Grey);
    border-radius: 15px;
    cursor: pointer;
    font-size: 1.2rem;
    color: #333;
    background-color: var(--White);
    transition: all 0.3s ease;
    text-align: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.licenseCardOption:hover {
    border-color: var(--Sand);
    background-color: var(--Grey);
    color: var(--Sand);
    scale: 1.1;

}

.selected {
    border-color: var(--Green);
    background-color: #007bff;
    color: #fff;
}

.iconStyle {
    font-size: 3rem;
    color: var(--Sand);
    margin-bottom: 10px;
}
.inputStyle {
    font-size: 1rem;
    width: 100%;
    max-width: 20vw;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

/* Button Styling */
.submitButton {
    width: 100%;
    max-width: 20vw;
    padding: 15px;
    margin-top: 20px;
    font-size: 1.2rem;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    background-color: var(--Green);
    color: white;
    cursor: pointer;
    transition: box-shadow 0.3s ease;
}

.submitButton:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}

/* Error Message */
.error {
    color: red;
    font-size: 1rem;
    margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .licenseCard {
        max-width: 100%;
        padding: 20px;
    }

    .inputStyle {
        font-size: 1rem;
    }

    .submitButton {
        font-size: 1rem;
    }
}


.pageStyle{
    width: 100vw;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}