.Signup-container {
    z-index: 99;
    top: 0;
    left: 0;
    display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: var(--Sand);
  background-image: url('../../public/images/mainpage/img1.jpg');

}
.Signup-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(174, 140, 101, 0.7); /* Adjust color and opacity as needed */
    z-index: -1; /* Positioned just above the background image, but below container content */
}


/* .
sgnp-ttl input {
    text-align: center;
    padding: 10px;
    margin: 10px 0;
   

    border: 2px solid var(--Gray); 
    border-radius: 5px;
    font-size: 16px; 
    background-color: var(--Gray); 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4); 
}
.sgnp-ttl input::placeholder {
    color: var(--White); 
    opacity: 0.5; 
}

.sgnp-ttl input:focus {
    border-color: var(--White); 
    color: var(--White);
    outline: none; 
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5); 
} 
*/

/* form starting stylings ------------------------------- */
.sgnpgroup 			  { 
    position:relative; 
    margin-bottom:45px; 
  }
  .sgnpinput 				{
    font-size:18px;
    padding:10px 10px 10px 0px;
    display:block;
    width:290px;
    border:none;
  }
  .sgnpinput:focus 		{ outline:none; }
  
  /* LABEL ======================================= */
  .sgnplabel 				 {
    color:var(--Grey); 
    font-size:18px;
    font-weight:normal;
    position:absolute;
    pointer-events:none;
    font-family: CenturyGothic;
    left:5px;
    top:10px;
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
  }
  
  /* active state */
  .sgnpinput:focus ~ label, .sgnpinput:valid ~ label 		{
    top:-22px;
    font-size:18px;
    color:var(--Green);
    font-weight: bolder;
  }
  
  /* BOTTOM BARS ================================= */
  .sgnpbar 	{ position:relative; display:block; width:300px; }
  .sgnpbar:before, .sgnpbar:after 	{
    content:'';
    height:2px; 
    width:0;
     
    position:absolute;
    background:var(--Green); 
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
  }
  .sgnpbar:before {
    left:50%;
  }
  .sgnpbar:after {
    right:50%;
     
  }
  
  /* active state */
  .sgnpinput:focus ~ .sgnpbar:before, .sgnpinput:focus ~ .sgnpbar:after {
    width:50%;
  }
  
  /* HIGHLIGHTER ================================== */
  .sgnphighlight {
    position:absolute;
    height:60%; 
    width:100px; 
    top:25%; 
    left:0;
    pointer-events:none;
    opacity:0.5;
  }
  
  /* active state */
  .sgnpinput:focus ~ .sgnphighlight {
    -webkit-animation:inputHighlighter 0.3s ease;
    -moz-animation:inputHighlighter 0.3s ease;
    animation:inputHighlighter 0.3s ease;
  }
  
  /* ANIMATIONS ================ */
  @-webkit-keyframes inputHighlighter {
      from { background:var(--Green); }
    to 	{ width:0; background:transparent; }
  }
  @-moz-keyframes inputHighlighter {
      from { background:var(--Green); }
    to 	{ width:0; background:transparent; }
  }
  @keyframes inputHighlighter {
      from { background:var(--Green); }
    to 	{ width:0; background:transparent; }
  }


  .sgnp-sub {
    border-radius: 24px;
    width: 150px;
    height: 50px;
    background-color: var(--White);
    border-color: var(--White);
    transition: 0.3s;
    font-family: CenturyGothic;
    font-size: 16px;
    margin-left: 40px;
    margin-bottom: 45px;

  }

  .sgnp-sub:hover {
    background-color: var(--Green);
    color: var(--White);
    border-color: var(--Green);
    cursor: pointer;
    
  }
  .sgnp-dl {
    border-radius: 24px;
    position: absolute;
    width: 100px;
    height: 50px;
    background-color: var(--Sand);
    border-color: var(--Sand);
    color: var(--White);
    transition: 0.3s;
    font-family: CenturyGothic;
    font-size: 12px;
    top: 0;
    right: 0;
    opacity: 0;
    transition: 1s ease-in-out;

  }

  .sgnp-dl:hover {
    background-color: var(--Sand);
    color: var(--Black);
    border-color: var(--Sand);
    opacity: 1;

    
  }


  