/* searchengine.module.css */

.container {
    display: flex;
    flex-direction: row;
    gap: 10%;
    justify-content: center;
  }
  

  
  .searchSection h1,
  .searchSection p {
    margin: 0;
  }
  
  .form {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .inputField {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 200px;
    margin: 0;
    margin-right: 10px;
  }
  
  .searchButton {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: var(--Sand);
    color: var(--White);
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .searchButton > svg {
    margin-right: 5px;
  }
  
  .productListContainer {
    width: 400px;
    background-color: var(--Grey);
    height: 600px;
    margin-top: 27px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
  }
  
  .productListTitle {
    display: flex;
    justify-content: center;
  }
  
  .productCount {
    margin: 0;
    padding: 0;
  }
  
  .productList {
    list-style: none;
    width: 375px;
    height: 240px;
    overflow-y: scroll;
    padding: 0;
    margin: 0;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .listItem {
    background-color: var(--White);
    width: 310px;
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1px;
    border-radius: 5px;
    color: var(--Black);
  }
  
  .listItemContent {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
  }
  
  .linkStyle {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .linkText {
    color: var(--Black);
    text-decoration: none;
    font-size: 12px;
    
  }
  
  .deleteIcon {
    color: var(--Green);
    cursor: pointer;
    margin-right: 10px;
  }
  
  .checkboxContainer {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .checkboxLabel {
    font-size: 10px;
    max-width: 300px;
    margin-left: 10px;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background-color: var(--White);
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    width: 90%;
    max-height: 80%;
    overflow-y: auto;
  }
  
  .modalContent {
    margin: 20px 0;
    text-align: left;
  }
  
  .closeButton {
    background-color: var(--Sand);
    color: var(--Black);
    border: none;
    cursor: pointer;
  }
  
  .acceptButton {
    margin: 30px;
    border: none;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
    background-color: var(--Sand);
    color: var(--White);
  }
  
  .dropdown {
    display: flex;
    flex-direction: column;
  }
  
  .dropdown h2 {
    margin: 0;
  }
  
  .foundProductsList {
    list-style: none;
    text-align: left;
    padding: 0;
    margin: 0;
    max-width: 600px;
  }
  
  .foundProductItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    background-color: var(--Grey);
    margin: 5px;
    padding: 10px;
    border-radius: 10px;
  }
  
  .productInfo {
    display: flex;
    flex-direction: column;
    width: 400px;
    gap: 5px;
  }
  
  .productName {
    margin: 2px 0;
    font-size: 14px;
  }
  
  .producerName {
    margin: 2px 0;
    font-size: 12px;
    color: #555;
  }
  
  .productLink {
    margin: 2px 0;
    font-size: 12px;
    color: #555;
  }
  
  .actionButtons {
    padding: 5px 10px;
    font-size: 12px;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .notFoundProducts {
    margin-top: 10px;
  }
  