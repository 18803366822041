.fileContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-bottom: 8px;
    
}
.progressBar {
    width: 100%;
    height: 10px;
    border-radius: 5px;
    overflow: hidden;
  }
  
.fileLink {
    text-decoration: none;
    color: #007BFF;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.fileLink:hover {
    color: #0056b3;
}

.fileIcon {
    font-size: 20px;
    cursor: pointer;
}

.fileIconDelete {
    color: red;
}

.fileInput {
    padding: 6px 12px;
    font-size: 14px;
}


.certificationButton  {
    padding: 6px 8px;
    font-size: 13px;
    
    background-color: var(--White);
    color: var(--Sand);

    border: 2px solid var(--Sand); 
    border-radius: 4px; 
    cursor: pointer;
}

.certificationButton:hover {
    color: var(--White);
    background-color: var(--Sand);
}

.modalContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    z-index: 1000;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modalContent {
    display: flex;
    flex-direction: column;
}

.uploadTable{
    table-layout: fixed; 
    border-spacing: 0;
    /* border: 2px red solid; */
}

.uploadTable > thead > tr > th {
    border: 2px solid var(--Sand); 
    text-align: center;
    height: 4vh; 
    overflow: hidden; 
    padding: 2px 2px;
    text-overflow: ellipsis;
    background-color: var(--White);
    max-width: 150px;
    font: 1.2em GothicB;
    min-width: 100px;
    color: var(--Sand);
    border-bottom: 5px solid var(--Sand);
    border-top: 0px;
}

.uploadTable > tbody > tr > td {
    border: 2px solid var(--Sand); 
    text-align: center;
    height: 4vh; 
    overflow: hidden; 
    text-overflow: ellipsis;
    background-color: var(--White); 
    color: var(--Black); 
    font: 0.9em Gothic;
    
    max-width: 250px;
    border-bottom: 0px;
    min-width: 100px;
}



.dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    max-height: 40px;
    border-radius: 10px;
    background-color: var(--White);
    color: var(--Black);
    font-size: 16px;
    cursor: pointer;
}

.dropzone:hover {
    background-color: var(--Sand);
    color: var(--White);
}

.submitButn {
    padding: 6px 8px;
    font-size: 13px;
    background-color: var(--White);
    color: var(--Black);
    border: 2px solid var(--Sand); 
    border-radius: 4px; 
    cursor: pointer;
}

.submitButn:hover {
    color: var(--White);
    background-color: var(--Sand);
}
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modalContainer {
    background: white;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    z-index: 1001;
  }
  