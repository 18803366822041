/* BottomSection.css */

.BottomSection__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
}

.BottomSection__largeImage {
    width: 80%;
    margin-bottom: 20px;
}

.BottomSection__textBlock {
    width: 60%;
    text-align: center;
    color: black;
    font-size: 1.1em;
    margin-bottom: 20px;
}

.BottomSection__header {
    color: var(--Green);
    font-family: AMIA;
    font-size: 1.9em;
    text-align: left;
    margin-left: 0;
}

.BottomSection__centeredImage {
    width: 60%;
    border-radius: 10%;
    border: 1px solid grey;
    object-fit: cover;
    margin: 1%;
}

.BottomSection__logoGrid {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

}

.BottomSection__icon {
    color: var(--Green); 
    animation: transform 0.5s;  
}

.BottomSection__icon:hover {
    transform: scale(1.1);
    
}
/* .BottomSection__icon--mail {
    background-color: var(--Green);
    color: var(--White);
    border-radius: 7%;
    max-height: 50px;
    
} */

/* Add more unique styles as needed */
