.deleteProjectOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    color: var(--Black);
}
  
  .deleteProjectContent {
    background-color: var(--White) !important;
    min-width: 500px;

    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  
  .deleteProjectContent h2 {
    color: var(--Black);
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
  } 
  
  .projectList {
    color: var(--Black);
    max-height: 200px;
    overflow-y: auto;
    margin-bottom: 20px;
  }
  
  .projectItem {
    padding: 10px;
    color: var(--Black);
    margin: 5px 0;
    cursor: pointer;
    max-width: 60%;
    justify-self: center;
    
  }
  .projectItem:hover {
    background-color: var(--Grey);

  }
  .projectItem.selected {
    background-color: var(--Grey);
  }

  .confirmOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
  }
  
  .confirmContent {
    background-color: white;
    color: var(--Black);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    text-align: center;
  }