/* FeedbackButton.css */
.feedbackButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: var(--Sand);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  z-index: 2147483648;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
}

.feedbackButton:hover {
  background-color: var(--Green);
}
