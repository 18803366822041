

.h-proj {
    font-family: GOTHICB;
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: var(--);
    margin: 10px;
    padding: 10px;
    color: var(--Green);
    
}

.Proj-back{
  display: flex;
  align-self: center;
  justify-self: center;
  height: fit-content;
  margin: 5px;
  padding: 5px 10px;
  color: var(--Green);
  border: 2px solid var(--Green) ;
  border-radius: 5px;
  background-color: var(--White);
  transition: 0.3s ease-in-out;
  cursor: pointer;

}


/* display: flex;
max-width: fit-content;
color: var(--Green) ;
padding: 5px 10px;
border-radius: 5px;
margin: 5px;
height: fit-content;


 */

.Proj-back:hover {
    background-color: var(--Sand);
    color: var(--White);
    border-color: var(--Sand);
  }


.Proj-cont {
  display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    
}

/* 
.category-group {
    margin: 0;
    padding: 0;

} */


