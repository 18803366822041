/* DetailPage.css */

.page-container_detail {
    display: flex;
    flex-direction: column; /* ensures children of this container stack vertically */
    align-items: center; 
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #F5F5F7;
    
}

.detail-container_detail {
    display: flex;
    flex-direction: row; /* Ensuring that items inside are in a row format */
    align-items: flex-start; /* Change 'start' to 'flex-start' */
    width: 100%;
    max-width: 1200px; /* Optional: to ensure content doesn't stretch too wide on larger screens */
}

.page-container_detail h1 {
    color: #334C31;
}

.largeImageStyle_detail {
    background-color: gray;

    width: 350px;
    margin: 20px;
    margin-top: 5vh;
    
}

.detailpage-Image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: zoom-in;
    
}
.detail-content_detail {
    font-family: GOTHICB;
    flex: 1;
    padding-left: 20px;
    max-width: 60%; /* Set a max-width to constrain the text */
}

.detail-content_detail h3 {
    font-family: GOTHICB;    
}

.detail-content_detail p {
    font-family: GOTHIC;
    color: #212426;
    line-height: 1.6; /* Improve readability by increasing line height */
}

.detail-content_detail div {
    display: flex;
    flex-wrap: wrap;
}

.smallImagePlaceholderStyle_detail {
    background-color: #F5F5F7;
    height: 60px;
    width: 60px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.table-title {
    color: #334C31;
    margin-top: 10px; /* Adjust spacing as needed */
    text-align: center; /* Center the title if you prefer */
}

.material-table {
    margin-top: 10px; /* Add some space between the detail content and the table */
    width: 100%;
    
    max-width: 1500px;
    
}

.material-table table {
    width: 100%;
    border-collapse: collapse;
}

.material-table th, 
.material-table td {
    padding: 10px;
    border: 1px solid black;
    text-align: left;
}

.material-table th {
    background-color: #ddd;
}

.table-selector {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.table-selector button {
    font-family: GOTHICB;
    font-size: medium;
    align-items: center; /* Centers the text and radio button */
    gap: 5px;
    padding: 10px;
    border: 1px solid #000;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--White);
    color: var(--Black);
}

.table-selector button:hover {
    background-color: var(--Sand);
    color: white;
}

.table-selector button.active {
    background-color: var(--Sand);
    color: white;
}

.expandButton {
  background: none;
  border: none;
  color: gray;
  cursor: pointer;
  padding: 0;
  font-size: 1em;
}

.expandButton:hover {
  text-decoration: underline;
}
