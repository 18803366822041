/* General container for each filter category */
.filterCategoryContainer {
  position: relative;
}

/* Normal category button */
.filterCategory {
  font-family: CenturyGothic;
  color: var(--Black);
  width: 200px;
  margin-top: 27px;
  padding: 10px;
  cursor: pointer;
  border: 2px solid var(--Sand);
  border-radius: 18px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  font-size: medium;
}
.filterCategory > p {
  margin: 0;
  font-weight: 500;
  font-size: 9px;
  font-family: CenturyGothic;
}

/* Category Name */
.categoryName {
  flex-grow: 1;
  text-align: left;
}

/* Normal Hover Effect */
.filterCategory:hover {
  background-color: var(--Sand);
  color: var(--White);
  border: 2px solid var(--White);
}

.filterCategory:hover .arrowIcon {
  color: var(--White);
}

/* Disabled Categories */
.disabledCategory {
  cursor: default;
  opacity: 0.6;
  border-color: var(--Sand);

  background-color: transparent;
}

/* Remove Hover Effect from Disabled Categories */
.disabledCategory:hover {
  background-color: transparent;
  color: var(--Black);
  border: 2px solid var(--Sand);
}

/* "Kommer snart" text */
.comingSoonText {
  color: var(--Sand);
}

/* Arrow Icon Styling */
.arrowIcon {
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  color: var(--Sand);
  transition: transform 0.5s ease-in-out, color 0.3s ease-in-out;
}

/* Active Arrow Rotation */
.arrowIcon.active {
  transform: rotate(180deg);
}

/* Expand/Collapse Content */
.filterContent {
  background-color: var(--White);
  min-width: 260px;
  padding-right: 10px;
  padding-left: 10px;
  transition: max-height 0.5s ease-in-out;
  max-height: 0;
  width: auto;
  border: 1px solid var(--Grey);
  border-radius: 5px;
  overflow: hidden;
}

/* Expand state */
.filterContent.expand {
  max-height: 550px;
  max-width: 275px;
  overflow: auto;
}

/* Collapse state */
.filterContent.collapse {
  max-height: 0;
  overflow: hidden;
  padding: 0;
  border: none;
}

/* Shrink the margin so the content is better visible on the bottom of the page */
@media (max-width: 1200px) {
  .filterCategory {
    margin-top: 4px;
    font-size: small;
  }
}
