.imageContainer {
  position: relative;  /* Change from absolute to relative */
  display: flex;
  width: 100%;
  height: 90vh;
  overflow: hidden;
}

.imagesMain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0; 
  animation: fade 16s infinite ease-in-out; /* Total duration for all images */
}

.imageContainer .imagesMain:nth-child(1) {
  animation-delay: 0s; /* First image starts immediately */
}

.imageContainer .imagesMain:nth-child(2) {
  animation-delay: 4s; /* Second image starts after 5 seconds */
}

.imageContainer .imagesMain:nth-child(3) {
  animation-delay: 8s; /* Third image starts after 10 seconds */
}

.imageContainer .imagesMain:nth-child(4) {
  animation-delay: 12s; /* Fourth image starts after 15 seconds */
}

  
@keyframes fade {

   
  10%, 20% {
    opacity: 1;
  }
  
  40%, 100% {
    opacity: 0;
  }
}

.banner {
  width: 100%;
  transform: scale(1);
  transform-origin: 0 0;
  text-align: center;
  position: absolute;
  bottom: 0;
  /* background-color: var(--Grey); */
  z-index: 2; 
  /* opacity: 0.7;  */
  overflow: hidden; 
}

.banner>h2{
  transform: translateX(-10%);
  font-size: 1rem;
  font-family: 'AMIA';

}

.slider {
  display: flex;
  justify-self: center;
  align-items: center;
  height: 100%;
  animation: slide 20s infinite;
}

.logo {
  flex: 0 0 auto;
  max-width: 10vw; /* Adjust width relative to banner */
  justify-items: center;
  padding-left: 2vh;
  padding-right: 2vh;
}

@keyframes slide {
  from {
    transform: translateX(20%); /* Start content fully outside the viewport */
  }
  to {
    transform: translateX(20%); /* End content fully visible */
  }
}

.slider:hover {
  animation-play-state: paused; /* Pause animation on hover */
}

.logo:hover {
  transform: scale(1.1); /* Enlarge on hover */
 
}


