/* OmPageBanner.module.css */

.omPageBanner {
  width: 85%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
}

.omPageBannerImage {
  width: 100%;
  height: 500px; /* height of the picture */
  object-position: top; /* Cropped from the top */
  object-fit: cover;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

/* Overlay position fixed to the bottom left of the banner image */
.overlay {
  position: absolute;
  left: 80px;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 250px;
  font-weight: bold;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1),
    /* Shadow on top */ 0 5px 10px rgba(0, 0, 0, 0); /* No shadow on bottom */
}

.text {
  color: var(--Sand);
  font-weight: bold;
}

.overlayHeadline {
  color: var(--Sand);
  font-weight: bolder;
  font-family: "GOTHICB";
}

/*transparent effect */
.overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: blur(100px);
  z-index: -1;
}
