.faqPage {
  font-family: Arial, sans-serif;
  margin: 20px;
}

.searchInput {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.box {
  flex: 1 1 calc(30% - 10px);
  margin: 10px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  background-color: #f9f9f9;
  transition: background-color 0.3s, border 0.3s;
}

.box:hover {
  background-color: #f0f8ff;
  border: 1px solid #007bff;
}

.activeBox {
  border: 2px solid #007bff;
  background-color: #f0f8ff;
}

.dynamicContent {
  margin-top: 20px;
  padding: 20px;
  border-radius: 5px;
}

.collapsible {
  margin: 10px 0;
}

.collapsibleHeader {
  cursor: pointer;
  padding: 10px;
  margin: 0;
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.collapsibleHeader:hover {
  background-color: #e0e0e0;
}

.collapsibleContent {
  padding: 10px;
  margin-top: 5px;
  border: 1px solid var(--Sand);
  border-radius: 5px;
  background-color: #f9f9f9;
}

.relatedLinks {
  margin-top: 20px;
}

.relatedLinks h4 {
  margin: 0 0 10px;
}

.relatedLinks ul {
  list-style-type: none;
  padding: 0;
}

.relatedLinks li {
  cursor: pointer;
  padding: 5px 0;
  transition: color 0.3s;
}

.relatedLinks li:hover {
  color: #007bff;
}
