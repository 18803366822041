/* @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  } */
  /* .loader-icon {
    animation: spin 2s linear infinite;
    } */
   /* Loader Container */
/* Loader Container */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;

}

/* Stroke animation for the first element */
@keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 1688.27px;
    stroke-dasharray: 1688.27px;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 1688.27px;
  }
}

@keyframes animate-svg-fill-1 {
  0% {
    fill: transparent;
  }
  100% {
    fill: var(--White);
  }
}

.svg-elem-1 {
  animation: 
    animate-svg-stroke-1 1.5s ease-in-out 0s both; /* First element stroke */
  stroke: var(--Sand); /* Add stroke color to see stroke animation */
  stroke-width: 30; /* Optional: adjust stroke width */
  fill: transparent; /* Start with transparent fill */
}


@keyframes animate-svg-fill-2 {
  0% {
    fill: transparent;
  }
  100% {
    fill: var(--Sand);
  }
}

.svg-elem-2 {
  animation: 
    animate-svg-fill-2 0.5s ease 1.48s both; /* Delayed fill */
  stroke: var(--White); /* Add stroke color to see stroke animation */
  stroke-width: 0; /* Optional: adjust stroke width */
  fill: transparent; /* Start with transparent fill */
}