


.menuButton {
    background: transparent;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 4;
    color: var(--Sand);
    position: fixed; /* Make the button fixed position */
    top: 50%; /* Center it vertically */
    transform: translateY(-50%); /* Adjust for exact centering */
    left: 0; /* Start at the left edge */
    transition: left 0.3s; /* Add transition for the left property */
}

.menuButton.open {
    left: 250px; /* Move the button to the right by the width of the profileMenu */
}
  .profileMenu {
    position: fixed;
    bottom: 0; 
    left: -255px; /* Initial position outside the viewport */
    width: 250px;
    height: 82%;
    max-height: 82vh;
    background-color: var(--White);
    border : 2px solid var(--Sand);
    transition: left 0.3s; /* Transition effect */
    z-index: 2;
}

.profileMenu.open {
    left: 0; /* Move the menu inside the viewport when it's open */
}

.MenuMenuBtn{
    margin-left: 5%;
    display: flex;
    border: 1px solid var(--Sand);
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    z-index: 4;
    color: var(--Black);
    justify-content: center ;
    align-items: center;
    max-width: 80%;
    transition: ease-out;

}

.MenuMenuBtn:hover{
    background-color: var(--Sand);
    color: var(--White);
    transition: ease-out;
}

.MenuMenuIMG{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 50px;
    
}
