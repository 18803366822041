/* KategoriPage.css */
.pageKategoriContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    margin: auto;
    background-color: var(--White);
    padding-top: 40px;


}


.logoContainer {
    height: 60px;
    margin-right: 10px;

}

.searchInput {
    width: 80%;
    z-index: 2;
    border: none;
    outline: none ;

}

.searchInput:focus {
    outline: none;
    border: 1px solid var(--Green);
}


/* .searchButton {
    background-color: var(--Green);
    color: var(--White);
    width: 60px;
    height: 40px;
} */


.overlay {
    position: absolute;
    width: 100vw;
    height: 95vh;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.searchbarAndRadio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    font-family: CenturyGothic;
}

.box {
    width: 30%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
}

.boxesContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 80%;
    margin: auto;
}

.image {
    cursor: pointer;
    width: 33%;
    height: 33%;
}

.categoryTitle {
    margin: 5px 0 0 0;
    color: var(--Green);
}

.materialColumns {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 45%;
    margin: auto;
}

.materialColumn {
    width: 33%;
    display: flex;
    font-family: GOTHIC;
    font-weight: bolder;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 13px;
}

.boxTitle {
    font-family: CenturyGothic;
    margin: 5px 0 0 0;
    color: var(--Green);
}

.materialColumn > p {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    height: 40px;
    width: 110px;
    border: 3px solid var(--Sand);
    border-radius: 25px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
}

.materialColumn > p:hover {
    background-color: var(--Sand);
    color: var(--White);
}



.box > img {
    cursor: pointer;
    width: 33%;
    height: 33%;
}

.box > h3 {
    font-family: CenturyGothic;
    margin: 5px 0 0 0;
    color: var(--Green);
}

