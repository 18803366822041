/* Wrapper for checkbox */
.checkboxWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* Hide the checkbox input */
.checkboxWrapper input[type="checkbox"] {
    visibility: hidden;
    display: none;
}

/* Toggle Switch */
.toggle {
    position: relative;
    display: block;
    width: 40px;
    height: 20px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transform: translate3d(0, 0, 0);
}

/* Toggle background */
.toggle:before {
    content: "";
    position: relative;
    top: 3px;
    left: 3px;
    width: 34px;
    height: 14px;
    display: block;
    background: var(--Grey);
    border-radius: 8px;
    transition: background 0.2s ease;
}

/* Toggle knob (circle) */
.toggle span {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    display: block;
    background: var(--White);
    border-radius: 50%;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease;
}

/* Ripple effect */
.toggle span:before {
    content: "";
    position: absolute;
    display: block;
    margin: -18px;
    width: 56px;
    height: 56px;
    background: rgba(79, 46, 220, 0.5);
    border-radius: 50%;
    transform: scale(0);
    opacity: 1;
    pointer-events: none;
}

/* Checked state - background color change */
.checkboxWrapper input:checked + .toggle:before {
    background: var(--Green);
}

/* Checked state - knob movement */
.checkboxWrapper input:checked + .toggle span {
    background: var(--Black);
    transform: translateX(20px);
    transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
}

/* Checked ripple effect */
.checkboxWrapper input:checked + .toggle span:before {
    transform: scale(1);
    opacity: 0;
    transition: all 0.4s ease;
}

/* Label text styling */
.labelText {
    font-size: 0.9rem;
    color: var(--Black);
}
