/* Button Wrapper */
.buttonWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* Styled Button */
.button32 {
    background-color: var(--Sand);
    border-radius: 12px;
    color: var(--White);
    cursor: pointer;
    font-weight: bold;
    padding: 10px 15px;
    text-align: center;
    transition: all 0.2s ease;
    width: 100%;
    box-sizing: border-box;
    border: none;
    font-size: 16px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

/* Hover and Focus Effect */
.button32:not(:disabled):hover,
.button32:not(:disabled):focus {
    outline: none;
    background: var(--White);
    color: var(--Sand);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2), 0 3px 8px rgba(0, 0, 0, 0.15);
}

/* Disabled Button Style */
.button32:disabled {
    filter: saturate(0.2) opacity(0.5);
    cursor: not-allowed;
}

/* Label Text Styling */
.labelText {
    font-size: 1rem;
    color: var(--White);
    font-weight: bold;
}
