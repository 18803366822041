/* FeedbackModal.css */

.feedbackModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.feedbackModalContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
}

.feedbackModalContent h2 {
  margin-top: 0;
}

.feedbackModalContent label {
  display: block;
  margin-bottom: 15px;
}

.feedbackModalContent input,
.feedbackModalContent textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  box-sizing: border-box;
}

.feedbackModalContent textarea {
  resize: vertical;
  height: 100px;
}

.modalButtons {
  display: flex;
  justify-content: flex-end;
}

.modalButtons button {
  margin-left: 10px;
  padding: 8px 16px;
}
